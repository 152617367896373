import { FC, useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getSocialConfiguration } from 'api/apiRequest';
import GoogleLoginButton from './GoogleLogin';
// import FacebookLoginButton from './FacebookLogin';

type Props = {
	setOpen: React.Dispatch<React.SetStateAction<any>>;
	onLoginSuccess: () => void;
};

export const SocialLogin: FC<Props> = ({ setOpen, onLoginSuccess }) => {
	const queryClient = useQueryClient();
	const [width, setWidth] = useState(0);
	const elementRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const element = elementRef?.current;
		if (element) {
			// eslint-disable-next-line
			setWidth(element.offsetWidth - 2);
		}
	}, [elementRef]);

	const { data } = useQuery(
		['socialConfiguration'],
		async () => await getSocialConfiguration(),
		{
			retry: 0,
		}
	);

	//clear query cache
	useEffect(() => {
		queryClient.invalidateQueries(['socialConfiguration']);
	}, [queryClient]);

	return (
		<div>
			<div className="flex gap-2 mb-3 justify-center items-center">
				<span className="border border-t border-[#59648E] w-[138px]"></span>
				<span>or</span>
				<span className="border border-t border-[#59648E] w-[138px]"></span>
			</div>
			{/* Social buttons */}
			{data?.data && (
				<div ref={elementRef}>
					<GoogleLoginButton
						width={width.toString()}
						closeModal={setOpen}
						onLoginSuccess={onLoginSuccess}
					/>
					{/* <FacebookLoginButton
						// width={width.toString()}
						appId={data?.data?.facebook?.appId}
						// closeModal={setOpen}
					/> */}
				</div>
			)}
		</div>
	);
};
