import { useState, useEffect } from 'react';
// import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { _isUserLoggedIn } from 'utils';
import { SelectLanguage } from './SelectLanguage';
import { Onboarding1 } from './Onboarding1';
import { Onboarding2 } from './Onboarding2';
import { Onboarding3 } from './Onboarding3';
import { Subscribe } from './Subscribe';
import errorHandler from 'handlers/errorHandler';
import { socialLoginTelegram } from 'api/apiRequest';
// import { getUserProfile } from 'api/profileApiService';
// import { initData, initDataUnsafe } from 'config';
import CircularLoader from 'components/loader/CircularLoader';

export function OnboardingPage() {
	const navigate = useNavigate();
	const loggedIn = _isUserLoggedIn();
	const domain = process.env.REACT_APP_HAMMER_GAMES_DOMAIN || 'defaultDomain';
	const [onboardingView, setOnboardingView] = useState('language');

	// Get the current URL
	const location = useLocation();
	// Access the 'start' parameter
	const queryParams = new URLSearchParams(location.search);
	const query = queryParams.get('start');

	const initDataUnsafe = window?.Telegram?.WebApp?.initDataUnsafe || {};
	const initData = window?.Telegram?.WebApp?.initData || {};

	const { mutate: telegramMutate, isLoading } = useMutation(
		socialLoginTelegram,
		{
			onError: (error: any) => {
				errorHandler(error, true);
			},
			onSuccess: (res: any) => {
				if (
					res?.data?.accessSecret === null ||
					res?.action === 'country_not_in_profile'
				) {
					// completeRegistration(
					// 	res?.data?.accessToken,
					// 	res?.data?.device?.cfIpCountry
					// );
					navigate('/auth/complete-registration', {
						state: {
							deviceCountry: res?.data?.device?.cfIpCountry,
							accessToken: res?.data?.accessToken,
							referrer: query,
						},
					});
				} else {
					localStorage.setItem('game-user-token', res?.data?.accessToken);
					localStorage.setItem('game-user-secret', res?.data?.accessSecret);
					if (res?.data?.user) {
						localStorage.setItem(
							'game-user-obj',
							JSON.stringify(res?.data?.user)
						);
					}
					// navigate('/play');
				}
			},
			retry: 0,
		}
	);

	// const completeRegistration = (accessToken: any, cfIpCountry: any) => {

	// 	// Send a POST request using Axios
	// 	const apiUrl = `${process.env.REACT_APP_HAMMER_GAMES_STAGING_API}/api/auth/registration/complete-register`;
	// 	const customHeaders = {
	// 		Accept: 'application/json',
	// 		'Content-Type': 'application/json',
	// 		Domain: `${process.env.REACT_APP_HAMMER_GAMES_DOMAIN}`,
	// 		Authentication: 'Basic ' + accessToken,
	// 		Authorization: 'Basic parole',
	// 	};

	// 	const postdata = {
	// 		countryCode: cfIpCountry,
	// 	};

	// 	axios
	// 		.post(apiUrl, postdata, { headers: customHeaders })
	// 		.then(function (res: any) {
	// 			// Handle success
	// 			localStorage.setItem('game-user-token', res?.data?.data?.accessToken);
	// 			localStorage.setItem('game-user-secret', res?.data?.data?.accessSecret);
	// 			if (res?.data?.data?.user) {
	// 				localStorage.setItem(
	// 					'game-user-obj',
	// 					JSON.stringify(res?.data?.data?.user)
	// 				);
	// 			}
	// 			navigate('/play');
	// 		})
	// 		.catch(function (error) {
	// 			// Handle error
	// 			console.error('Error making POST requests:', error);
	// 		});
	// };

	useEffect(() => {
		if (
			Object.keys(initDataUnsafe).length > 0 &&
			Object.keys(initData).length > 0 &&
			!loggedIn
		) {
			telegramMutate({
				data: {
					initData: initData,
					initDataUnsafe: initDataUnsafe,
				},
				domain,
			});
		}
		// eslint-disable-next-line
		// }, [domain, loggedIn, initDataUnsafe, initData]);
	}, []);

	return (
		<div className="relative h-full max-w-[540px] w-full mx-auto">
			{isLoading ? (
				<CircularLoader />
			) : (
				<div className="h-full max-w-[540px] w-full mx-auto">
					{onboardingView === 'language' && (
						<SelectLanguage setOnboardingView={setOnboardingView} />
					)}
					{onboardingView === 'welcome' && (
						<Onboarding1 setOnboardingView={setOnboardingView} />
					)}
					{onboardingView === 'play' && (
						<Onboarding2 setOnboardingView={setOnboardingView} />
					)}
					{onboardingView === 'connect' && <Onboarding3 />}
					{onboardingView === 'subscribe' && <Subscribe />}
				</div>
			)}
		</div>
	);
}
