import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { AuthFormAction } from 'components/forms/auth/layout';
import TextInput from 'components/inputs/text';
import { Button } from 'components/button/Button';
import { ResendVerification } from 'components/forms/auth/ResendVerification';
// import { ReactComponent as Back } from 'assets/icons/arrow-left-circle.svg';
import { verifyUserApi, loginUserApi } from 'api/apiRequest';
import { authActions } from 'store/slices/authSlice';
import errorHandler from 'handlers/errorHandler';
import { useNavigate } from 'react-router-dom';

export const VerifyEmailPage: FC = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const [data, setData] = useState('');
	const { email, password } = useSelector((state: any) => state.authReducer);

	const { isLoading, mutate } = useMutation(verifyUserApi, {
		onError: (error: Error) => {
			errorHandler(error, true);
		},
		onSuccess: () => {
			newMutate({
				username: email,
				password: password,
				code: 'string',
			});
		},
		retry: 0,
	});

	const { mutate: newMutate } = useMutation(loginUserApi, {
		onError: (error: Error) => {
			errorHandler(error, true);
			dispatch(authActions.regEmail(''));
			dispatch(authActions.regPassword(''));
			dispatch(authActions.regPhone(''));
		},
		onSuccess: (res: any) => {
			localStorage.setItem('game-user-token', res?.data?.accessToken);
			localStorage.setItem('game-user-secret', res?.data?.accessSecret);
			localStorage.setItem('game-user-obj', JSON.stringify(res?.data?.user));
			// setFormView('onboarding');
			navigate('/');
			dispatch(authActions.regEmail(''));
			dispatch(authActions.regPassword(''));
			dispatch(authActions.regPhone(''));
		},
		retry: 0,
	});

	const onSubmit = () => {
		const formData = {
			email: email,
			otp: data,
		};
		mutate(formData);
	};
	return (
		<div className="lg:grid grid-cols-1 items-center gap-5 text-white px-0 sm:px-4">
			<div className="flex gap-2 items-center mb-8">
				{/* <Back
					className="w-[34px] h-[34px] cursor-pointer"
					onClick={() => {
						navigate(-1);
						dispatch(authActions.regEmail(''));
						dispatch(authActions.regPassword(''));
						dispatch(authActions.regPhone(''));
						dispatch(authActions.regCountry(''));
					}}
					data-testid="back-to-homepage"
				/> */}
				<h3 className="text-2xl font-bold">Verify Email</h3>
			</div>

			<AuthFormAction>
				<p className="font-medium text-grey">
					Please enter the security verification code sent to your email
				</p>
				<p className="mb-10 font-medium text-green">{email}</p>
				<TextInput
					type={'number'}
					label={'Enter verification code'}
					data-testid="verification-code"
					onChange={(e: any) => setData(e.target.value)}
				/>

				<Button
					text={'Verify Email'}
					loading={isLoading}
					data-testid="email-verification-button"
					className={
						'text-[15px] text-black font-bold btn-primary h-[56px] rounded-full mb-3 mt-5 justify-center block w-full px-8'
					}
					onClick={() => onSubmit()}
				/>

				<ResendVerification defaultTimer={true} />
			</AuthFormAction>
		</div>
	);
};
