import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'components/button/Button';
import Onboard from 'assets/images/onboarding.png';
import { useNavigate } from 'react-router-dom';

type Props = {
	setOpen: React.Dispatch<React.SetStateAction<any>>;
};

export const Onboarding: FC<Props> = ({ setOpen }) => {
	const navigate = useNavigate();

	const { email } = useSelector((state: any) => state.authReducer);
	return (
		<div
			className="lg:grid grid-cols-1 sm:grid-cols-2 items-center gap-5 text-white px-0 sm:px-4"
			style={{ paddingTop: '50px' }}
		>
			<div className="max-w-[325px] pt-2 sm:pt-[40px]">
				<h2 className="text-2xl font-medium mb-3">{email}</h2>
				<h2 className="text-3xl sm:text-4xl font-medium mb-8">
					You are welcome to Hammer Games
				</h2>
				<h2 className="text-xl sm:text-2xl text-grey font-medium">
					Watch the quick video tutorial to get acquainted with the game.
				</h2>
			</div>

			<div>
				<figure className="rounded-[13px] bg-gray-500 max-h-[320px] rounded-2xl">
					<img src={Onboard} alt="onboarding" />
				</figure>

				<div className="mt-6 flex gap-6">
					<Button
						text={'Nah, I’m Good'}
						className={
							'text-[15px] text-green font-medium h-[48px] border-2 border-green rounded-full mb-3 mt-8 justify-center block w-full px-6 sm:px-8'
						}
						data-testid="skip-onboarding-button"
						onClick={() => {
							setOpen(false);
							navigate('/');
						}}
					/>
					<Button
						text={'Watch Guide'}
						data-testid="onboarding-button"
						className={
							'text-[15px] text-black font-medium btn-primary h-[48px] rounded-full mb-3 mt-8 justify-center block w-full px-6 sm:px-8'
						}
						onClick={() => {
							setOpen(false);
							navigate('/learnmore');
						}}
					/>
				</div>
			</div>
		</div>
	);
};
