import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthFormAction } from 'components/forms/auth/layout';
import { SocialLogin } from 'components/forms/auth/socialLogin';
import TextInput from 'components/inputs/text';
import PhoneInput from 'components/inputs/phone';
import { Button } from 'components/button/Button';
import { CountryList } from 'utils/CountryList';
import { PhoneCountryList } from 'utils/PhoneCountryList';
import Mail from 'assets/icons/mail-outline.svg';
// import Phone from '../../../assets/icons/phone-outline.svg';
import { ReactComponent as Back } from 'assets/icons/arrow-left-circle.svg';
import { authActions } from 'store/slices/authSlice';
import SearchableCountries from 'components/inputs/searchableCountries';

const openTerms = () => {
	window.open('https://hammergames.tawk.help/article/terms', '_blank');
};
const openPrivacy = () => {
	window.open('https://hammergames.tawk.help/article/privacy-policy', '_blank');
};

interface Props {
	onLoginSuccess: () => void;
}

export const EmailRegistrationPage: FC<Props> = ({ onLoginSuccess }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const ref = queryParams.get('ref');

	const [validInput, setValidInput] = useState(false);
	const [inputType, setInputType] = useState('email');
	const [data, setData] = useState({
		input: '',
		countryCode: 'NG',
		advocateUserName: '',
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		if (name === 'input') {
			isPhoneNumberOrEmail(value);
			const new_value = value.toLowerCase();
			setData({ ...data, [name]: new_value });
		} else {
			setData({ ...data, [name]: value });
		}
	};

	const handlePhone = (e: any) => {
		const { name, value } = e.target;
		setData({ ...data, [name]: value });
		if (name === 'input') {
			const new_value = value.toLowerCase();
			setData({ ...data, [name]: new_value });
			setValidInput(true);
			dispatch(authActions.regPhone(value));
			dispatch(authActions.regUserType('phone'));
		}
	};

	useEffect(() => {
		const refName = ref;
		if (refName) {
			setData({ ...data, advocateUserName: refName });
			dispatch(authActions.regAdvocateUsername(refName));
		}
		dispatch(authActions.regCountry(data.countryCode));
		// eslint-disable-next-line
	}, []);

	function isPhoneNumberOrEmail(inputStr: any) {
		// Define regular expressions for emails
		// const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
		const emailPattern =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		// Check if the input matches the email pattern
		if (emailPattern.test(inputStr)) {
			setValidInput(true);
			dispatch(authActions.regEmail(inputStr));
			dispatch(authActions.regUserType('email'));
		} else {
			setValidInput(false);
		}
	}

	const onSubmit = () => {
		navigate('/auth/create-password');
	};

	const selectCountryOption = (option: any) => {
		const value = option?.code;
		dispatch(authActions.regCountry(value));
		setData({ ...data, countryCode: value });
	};

	return (
		<div className="lg:grid grid-cols-1 items-center gap-5 text-white px-0 sm:px-4 ">
			<div className="flex gap-2 items-center mb-8">
				<Back
					className="w-[34px] h-[34px] cursor-pointer"
					onClick={() => navigate(-1)}
					data-testid="back-to-homepage"
				/>
				<h3 className="text-2xl font-bold">Sign up on Hammer Games</h3>
			</div>
			<AuthFormAction>
				<h3 className="font-medium text-grey text-[15px] mb-6">
					Please enter your email we will send you a security verification code
				</h3>

				{inputType === 'email' && (
					<EmailRegistration
						selectCountryOption={selectCountryOption}
						handleChange={handleChange}
						data={data}
						setInputType={setInputType}
					/>
				)}

				{inputType === 'phone' && (
					<PhoneRegistration
						selectCountryOption={selectCountryOption}
						handlePhone={handlePhone}
						data={data}
						setInputType={setInputType}
					/>
				)}

				<TextInput
					type={'text'}
					name={'advocateUserName'}
					label={'Referral Code (Optional)'}
					value={data.advocateUserName}
					data-testid="register-referrer-input"
					placeholder={'Enter referral code here'}
					disabled={ref ? true : false}
					className={'mb-4'}
					onChange={(e: any) => {
						handleChange(e);
						dispatch(authActions.regAdvocateUsername(e?.target?.value));
					}}
				/>

				<Button
					text={'Continue'}
					data-testid="register-button"
					disabled={
						!data?.input || !data?.countryCode || !validInput ? true : false
					}
					className={
						'text-[15px] text-black font-bold btn-primary h-[56px] rounded-full mb-3 justify-center block w-full px-8'
					}
					onClick={onSubmit}
				/>

				<SocialLogin setOpen={() => {}} onLoginSuccess={onLoginSuccess} />

				<p className="mb-3 mt-5 text-xs font-medium text-grey">
					By joining Hammer Games, you agree that you are 18+ years old and you
					accept our{' '}
					<span
						className="text-green"
						onClick={openTerms}
						data-testid="to-terms-of-service"
					>
						Terms of Service,
					</span>{' '}
					<span
						className="text-green"
						onClick={openPrivacy}
						data-testid="to-privacy-policy"
					>
						Privacy Policy
					</span>{' '}
					and to receive emails and updates.
				</p>
			</AuthFormAction>
		</div>
	);
};

const EmailRegistration = ({
	selectCountryOption,
	handleChange, // setInputType,
}: any) => {
	return (
		<form className="mb-4">
			<SearchableCountries
				options={CountryList}
				label="Select your country"
				name={'restaurant_id'}
				placeholder={'Select your country'}
				selectOption={selectCountryOption}
				data-testid="register-email-countrylist"
			/>

			<TextInput
				data-testid="emailInput"
				type={'email'}
				name={'input'}
				label={'Enter Email'}
				onChange={(e) => {
					handleChange(e);
				}}
				className="mt-4"
				placeholder={'Enter email here'}
			/>
		</form>
	);
};

const PhoneRegistration = ({
	selectCountryOption,
	handlePhone,
	data,
	setInputType,
}: any) => {
	const dispatch = useDispatch();
	return (
		<form className="mb-8">
			<SearchableCountries
				options={PhoneCountryList}
				label="Select your country"
				name={'restaurant_id'}
				placeholder={'Select your country'}
				selectOption={selectCountryOption}
				data-testid="register-phone-countrylist"
			/>

			<PhoneInput
				type={'tel'}
				name={'input'}
				label={'Enter Phone No.'}
				country={data?.countryCode}
				onChange={(e) => {
					handlePhone(e);
				}}
				className="mt-4"
				subLabel={
					<button
						type="button"
						onClick={() => {
							setInputType('email');
							dispatch(authActions.regEmail(''));
						}}
						data-testid="register-email-switch"
						className="text-text-green text-sm btn-primary-10 rounded-full flex items-center justify-center gap-2 px-4 py-1 mb-1"
					>
						<img src={Mail} alt="Phone" />
						Email
					</button>
				}
				data-testid="register-phone"
			/>
		</form>
	);
};
