import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';
import { useMutation } from '@tanstack/react-query';
import { socialLoginGoogle } from 'api/apiRequest';
import errorHandler from 'handlers/errorHandler';
import { authActions } from 'store/slices/authSlice';

const GoogleLoginButton = (props: any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const referralCode = queryParams.get('ref');

	const { mutate: googleMutate } = useMutation(socialLoginGoogle, {
		onError: (error: any) => {
			errorHandler(error, true);
		},
		onSuccess: (res: any) => {
			if (
				res?.data?.accessSecret === null ||
				res?.action === 'country_not_in_profile'
			) {
				dispatch(authActions.regAccessToken(res?.data?.accessToken));
				navigate('/auth/complete-registration');
			} else {
				localStorage.setItem('game-user-token', res?.data?.accessToken);
				localStorage.setItem('game-user-secret', res?.data?.accessSecret);
				if (res?.data?.user) {
					localStorage.setItem(
						'game-user-obj',
						JSON.stringify(res?.data?.user)
					);
				}
				props.onLoginSuccess();
				navigate('/');
			}
			props.closeModal(false);
		},
		retry: 0,
	});

	const loginGoogle = (token: any) => {
		if (referralCode) {
			const formData = {
				idToken: token,
				advocateUserName: referralCode,
			};
			googleMutate(formData);
		} else {
			const formData = {
				idToken: token,
			};
			googleMutate(formData);
		}
	};

	return (
		<div>
			<GoogleLogin
				onSuccess={(credentialResponse) => {
					loginGoogle(credentialResponse?.credential);
				}}
				onError={() => {
					console.log('Google Login Failed');
				}}
				text={'signup_with'}
				shape="circle"
				size="large"
				width={props.width}
				logo_alignment="left"
			/>
		</div>
	);
};

export default GoogleLoginButton;
