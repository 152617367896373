import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { AuthFormAction } from 'components/forms/auth/layout';
import TextInput from 'components/inputs/text';
import { Button } from 'components/button/Button';
import { ReactComponent as Back } from 'assets/icons/arrow-left-circle.svg';
import { loginUserApi } from 'api/apiRequest';
import errorHandler from 'handlers/errorHandler';

export const LoginWith2FAPage: FC = () => {
	const navigate = useNavigate();

	const [data, setData] = useState('');
	const { email, password } = useSelector((state: any) => state.authReducer);

	const { mutate, isLoading } = useMutation(loginUserApi, {
		onError: (error: Error) => {
			errorHandler(error, true);
		},
		onSuccess: (res: any) => {
			localStorage.setItem('game-user-token', res?.data?.accessToken);
			localStorage.setItem('game-user-secret', res?.data?.accessSecret);
			localStorage.setItem('game-user-obj', JSON.stringify(res?.data?.user));
			// setFormView('onboarding');
			navigate('/');
		},
		retry: 0,
	});

	const onSubmit = () => {
		const formData = {
			username: email,
			password: password,
			code: data,
		};
		mutate(formData);
	};

	return (
		<div className="lg:grid grid-cols-1 items-center gap-5 text-white px-0 sm:px-4">
			<div className="flex gap-2 items-center mb-8">
				<Back
					className="w-[34px] h-[34px] cursor-pointer"
					onClick={() => navigate(-1)}
					data-testid="back-to-homepage"
				/>
				<h3 className="text-2xl font-bold">Authenticator App Code</h3>
			</div>
			<AuthFormAction>
				<p className="font-medium text-grey mb-10">
					Please enter the 2FA code from your authenticator app
				</p>
				<TextInput
					type={'text'}
					data-testid="verification-code"
					label={'Enter verification code'}
					onChange={(e) => setData(e.target.value)}
				/>
				<Button
					text={'Confirm'}
					loading={isLoading}
					data-testid="verification-button"
					className={
						'text-[15px] text-black font-bold btn-primary h-[56px] rounded-full mb-3 mt-5 justify-center block w-full px-8'
					}
					onClick={() => onSubmit()}
				/>
			</AuthFormAction>
		</div>
	);
};
