import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { AuthFormTitle } from './layout/index';
import { AuthFormAction } from './layout/index';
import TextInput from '../../inputs/text';
import { Button } from 'components/button/Button';
import { ResendPhoneVerification } from './ResendPhoneVerification';
import { ReactComponent as Back } from '../../../assets/icons/arrow-left-circle.svg';
import { verifyPhoneApi, loginUserApi } from 'api/apiRequest';
import { authActions } from 'store/slices/authSlice';
import errorHandler from 'handlers/errorHandler';
import { useNavigate } from 'react-router-dom';

type Props = {
	setFormView: React.Dispatch<React.SetStateAction<any>>;
};

export const VerifyPhoneForm: FC<Props> = ({ setFormView }) => {
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const [data, setData] = useState('');
	const { password, userPhone, countryCode } = useSelector(
		(state: any) => state.authReducer
	);

	const { isLoading, mutate } = useMutation(verifyPhoneApi, {
		onError: (error: Error) => {
			errorHandler(error, true);
		},
		onSuccess: () => {
			newMutate({
				username: userPhone,
				password: password,
				userPhoneCountryCode: countryCode,
				code: 'string',
			});
		},
		retry: 0,
	});

	const { mutate: newMutate } = useMutation(loginUserApi, {
		onError: (error: Error) => {
			errorHandler(error, true);
			dispatch(authActions.regEmail(''));
			dispatch(authActions.regPassword(''));
			dispatch(authActions.regPhone(''));
			dispatch(authActions.regCountry(''));
		},
		onSuccess: (res: any) => {
			localStorage.setItem('game-user-token', res?.data?.accessToken);
			localStorage.setItem('game-user-secret', res?.data?.accessSecret);
			localStorage.setItem('game-user-obj', JSON.stringify(res?.data?.user));
			// setFormView('onboarding');
			navigate('/');
			dispatch(authActions.regEmail(''));
			dispatch(authActions.regPassword(''));
			dispatch(authActions.regPhone(''));
			dispatch(authActions.regCountry(''));
		},
		retry: 0,
	});

	const onSubmit = () => {
		const formData = {
			userPhone: userPhone,
			userPhoneCountryCode: countryCode,
			otp: data,
		};
		mutate(formData);
	};

	return (
		<div className="lg:grid grid-cols-1 sm:grid-cols-2 items-center gap-5 text-white px-0 sm:px-4">
			<AuthFormTitle>
				Sign up to join a community of millions of players.
			</AuthFormTitle>

			<AuthFormAction>
				<div
					className="flex gap-2 items-center mb-3 cursor-pointer"
					data-testid="to-register-button"
					onClick={() => {
						setFormView('register');
						dispatch(authActions.regEmail(''));
						dispatch(authActions.regPassword(''));
						dispatch(authActions.regPhone(''));
						dispatch(authActions.regCountry(''));
					}}
				>
					<Back className="w-[34px] h-[34px]" />
					<h3 className="text-2xl font-bold max-w-[280px]">
						Verify Phone number
					</h3>
				</div>
				<p className="font-medium text-grey">
					Please enter the security verification code sent to your phone number{' '}
				</p>
				<p className="mb-10 font-medium text-green">{userPhone}</p>

				<TextInput
					type={'text'}
					label={'Enter verification code'}
					data-testid="verification-code"
					onChange={(e) => setData(e.target.value)}
				/>

				<Button
					text={'Verify Phone'}
					loading={isLoading}
					disabled={data?.length < 5}
					data-testid="phone-verification-button"
					className={
						'text-[15px] text-black font-bold btn-primary h-[56px] rounded-full mb-3 mt-5 justify-center block w-full px-8'
					}
					onClick={() => onSubmit()}
				/>

				<ResendPhoneVerification defaultTimer={true} />
			</AuthFormAction>
		</div>
	);
};
