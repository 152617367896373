import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import TextInput from 'components/inputs/text';
import { AuthFormAction } from 'components/forms/auth/layout';
import { Button } from 'components/button/Button';
import { PasswordStrength } from 'components/common/PasswordStrength';
import { ReactComponent as Back } from 'assets/icons/arrow-left-circle.svg';
import { registerUserApi } from 'api/apiRequest';
import { authActions } from 'store/slices/authSlice';
import errorHandler from 'handlers/errorHandler';
import { cleanInput } from 'utils/cleanInput';

export const CreatePasswordPage: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [disabled, setDisabled] = useState(true);
	const { email, countryCode, advocateUserName, userPhone, type } = useSelector(
		(state: any) => state.authReducer
	);

	const handleChange = (e: any) => {
		const { value } = e.target;
		setConfirmPassword(value);
		if (value === password && value !== '') {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	};

	const { isLoading, mutate } = useMutation(registerUserApi, {
		onError: (error: Error) => {
			errorHandler(error, true);
		},
		onSuccess: () => {
			if (type === 'email') {
				navigate('/auth/register/verify-email');
			}
			if (type === 'phone') {
				navigate('/auth/register/verify-phone');
			}
		},
		retry: 0,
	});

	const onSubmit = () => {
		const lowerCountry_code = countryCode;
		if (userPhone !== '') {
			const formData = {
				password: password,
				userPhone: userPhone,
				countryCode: lowerCountry_code,
				userPhoneCountryCode: lowerCountry_code,
				advocateUserName,
			};
			cleanInput(formData);
			mutate(formData);
		} else {
			const formData = {
				email: email,
				password: password,
				countryCode: lowerCountry_code,
				advocateUserName,
			};
			cleanInput(formData);
			mutate(formData);
		}
	};
	return (
		<div className="lg:grid grid-cols-1 items-center gap-5 text-white px-0 sm:px-4">
			<div className="flex gap-2 items-center mb-8">
				<Back
					className="w-[34px] h-[34px] cursor-pointer"
					onClick={() => {
						navigate(-1);
						dispatch(authActions.regAdvocateUsername(''));
					}}
					data-testid="back-to-homepage"
				/>
				<h3 className="text-2xl font-bold">Create Password</h3>
			</div>
			<AuthFormAction>
				<div className="mb-10 flex items-start justify-between gap-4">
					<div>
						{type === 'phone' ? (
							<>
								<p className="font-medium text-grey">Phone Number:</p>
								<p className="font-medium text-white">{userPhone}</p>
							</>
						) : (
							<>
								<p className="font-medium text-grey">Your email:</p>
								<p className="font-medium text-white">{email}</p>
							</>
						)}
					</div>
					<button
						type="button"
						onClick={() => {
							navigate(-1);
						}}
						className="font-medium text-green"
					>
						Edit {type === 'phone' ? 'Phone' : 'Email'}
					</button>
				</div>

				<h3 className="font-medium text-grey text-[15px] mb-6">
					Please create a strong password to protect your account
				</h3>

				<form>
					<TextInput
						label={'New password'}
						passwordInput={true}
						data-testid="signup-password"
						subLabel={<PasswordStrength password={password} />}
						onChange={(e) => {
							setPassword(e.target.value);
							dispatch(authActions.regPassword(e?.target?.value));
							if (
								e.target.value === confirmPassword &&
								e.target.value.length > 5
							) {
								setDisabled(false);
							} else {
								setDisabled(true);
							}
						}}
					/>

					<p className="mb-8 text-sm text-red">Minimum of 6 characters.</p>

					<TextInput
						passwordInput={true}
						label={'Confirm password'}
						onChange={(e) => handleChange(e)}
						data-testid="confirm-password"
					/>

					<Button
						text={'Continue'}
						type="button"
						disabled={disabled}
						loading={isLoading}
						data-testid="registration-button"
						className={
							'text-[15px] text-black font-bold btn-primary h-[56px] rounded-full mb-3 mt-8 justify-center block w-full px-8'
						}
						onClick={() => onSubmit()}
					/>
				</form>
			</AuthFormAction>
		</div>
	);
};
