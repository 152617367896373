import { FC } from 'react';
import { SocialLogin } from './socialLogin';
import { AuthFormTitle } from './layout/index';
import { AuthFormAction } from './layout/index';
import { Button } from '../../button/Button';
import { mailClose } from 'assets';
import { ReactComponent as Arrow } from '../../../assets/icons/arrow-left-login.svg';

type Props = {
	setFormView: React.Dispatch<React.SetStateAction<any>>;
	setOpen: React.Dispatch<React.SetStateAction<any>>;
	onLoginSuccess: () => void;
};

const openTerms = () => {
	window.open('https://hammergames.tawk.help/article/terms', '_blank');
};
const openPrivacy = () => {
	window.open('https://hammergames.tawk.help/article/privacy-policy', '_blank');
};

export const JoinForm: FC<Props> = ({
	setFormView,
	setOpen,
	onLoginSuccess,
}) => {
	return (
		<div className="lg:grid grid-cols-1 sm:grid-cols-2 items-center gap-5 text-white px-0 sm:px-4">
			<AuthFormTitle>
				Sign up to join a community of millions of players.
			</AuthFormTitle>

			<AuthFormAction>
				<h3 className="text-2xl font-bold mb-8 max-w-[200px]">
					Login or Sign up to start playing.
				</h3>

				<button
					data-testid="to-login-button"
					onClick={() => setFormView('login')}
					className="text-[15px] text-black font-bold btn-primary h-[56px] rounded-full mb-3 text-center flex gap-2 items-center justify-center w-full"
				>
					<Arrow className="w-[24px] h-[24px]" />
					<span>Login</span>
				</button>

				<SocialLogin setOpen={setOpen} onLoginSuccess={onLoginSuccess} />

				<Button
					text={'Sign up with Email'}
					data-testid="to-register"
					className={
						'text-[15px] md:text-[13px] text-black font-bold bg-white h-[56px] rounded-full mb-3 mt-3 justify-center block w-full px-8'
					}
					icon={mailClose}
					iconClass="w-[1.4rem]"
					textClass="flex-1"
					onClick={() => setFormView('register')}
				/>

				<p className="my-3 text-xs font-medium text-grey">
					By joining Hammer Games, you agree that you are 18+ years old and you
					accept our{' '}
					<span
						className="text-green"
						onClick={openTerms}
						data-testid="to-terms-of-service"
					>
						Terms of Service,
					</span>{' '}
					<span
						className="text-green"
						onClick={openPrivacy}
						data-testid="to-privacy-policy"
					>
						Privacy Policy
					</span>{' '}
					and to receive emails and updates.
				</p>
			</AuthFormAction>
		</div>
	);
};
