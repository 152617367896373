import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthFormTitle } from './layout/index';
import { AuthFormAction } from './layout/index';
import TextInput from '../../inputs/text';
import PhoneInput from 'components/inputs/phone';
import { Button } from 'components/button/Button';
import { CountryList } from 'utils/CountryList';
import { PhoneCountryList } from 'utils/PhoneCountryList';
import { retrieveReferralUsername } from 'utils';
import Mail from '../../../assets/icons/mail-outline.svg';
// import Phone from '../../../assets/icons/phone-outline.svg';
import { ReactComponent as Back } from '../../../assets/icons/arrow-left-circle.svg';
import { authActions } from 'store/slices/authSlice';
import SearchableCountries from '../../inputs/searchableCountries';

type Props = {
	setFormView: React.Dispatch<React.SetStateAction<any>>;
};

export const JoinWithEmailForm: FC<Props> = ({ setFormView }) => {
	const dispatch = useDispatch();
	const [validInput, setValidInput] = useState(false);
	const [inputType, setInputType] = useState('email');
	const [data, setData] = useState({
		input: '',
		countryCode: 'NG',
		advocateUserName: '',
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		if (name === 'input') {
			isPhoneNumberOrEmail(value);
			const new_value = value.toLowerCase();
			setData({ ...data, [name]: new_value });
		} else {
			setData({ ...data, [name]: value });
		}
	};

	const handlePhone = (e: any) => {
		const { name, value } = e.target;
		setData({ ...data, [name]: value });
		if (name === 'input') {
			const new_value = value.toLowerCase();
			setData({ ...data, [name]: new_value });
			setValidInput(true);
			dispatch(authActions.regPhone(value));
			dispatch(authActions.regUserType('phone'));
		}
	};

	useEffect(() => {
		const refName = retrieveReferralUsername();
		if (refName) {
			setData({ ...data, advocateUserName: refName });
			dispatch(authActions.regAdvocateUsername(refName));
		}
		// eslint-disable-next-line
	}, []);

	function isPhoneNumberOrEmail(inputStr: any) {
		// Define regular expressions for emails
		// const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
		const emailPattern =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		// Check if the input matches the email pattern
		if (emailPattern.test(inputStr)) {
			setValidInput(true);
			dispatch(authActions.regEmail(inputStr));
			dispatch(authActions.regUserType('email'));
		} else {
			setValidInput(false);
		}
	}

	const onSubmit = () => {
		setFormView('create-password');
	};

	const selectCountryOption = (option: any) => {
		const value = option?.code;
		dispatch(authActions.regCountry(value));
		setData({ ...data, countryCode: value });
	};

	const openTerms = () => {
		window.open('https://hammergames.tawk.help/article/terms', '_blank');
	};
	const openPrivacy = () => {
		window.open(
			'https://hammergames.tawk.help/article/privacy-policy',
			'_blank'
		);
	};

	return (
		<div className="lg:grid grid-cols-1 sm:grid-cols-2 items-center gap-5 text-white px-0 sm:px-4">
			<AuthFormTitle>
				Sign up to join a community of millions of players.
			</AuthFormTitle>

			<AuthFormAction>
				<div
					className="flex gap-2 items-center mb-3 cursor-pointer"
					onClick={() => setFormView('join')}
					data-testid="back-to-login"
				>
					<Back className="w-[34px] h-[34px]" />
					<h3 className="text-2xl font-bold md:max-w-none max-w-[200px]">
						Sign up on Hammer Games
					</h3>
				</div>

				<p className="mb-6 text-grey">
					Please enter your email or phone number we will send you a security
					verification code
				</p>
				{inputType === 'email' && (
					<EmailRegistration
						selectCountryOption={selectCountryOption}
						handleChange={handleChange}
						data={data}
						setInputType={setInputType}
					/>
				)}

				{inputType === 'phone' && (
					<PhoneRegistration
						selectCountryOption={selectCountryOption}
						handlePhone={handlePhone}
						data={data}
						setInputType={setInputType}
					/>
				)}

				<TextInput
					type={'text'}
					name={'advocateUserName'}
					label={'Referral Code (Optional)'}
					value={data.advocateUserName}
					data-testid="register-referrer-input"
					placeholder={'Enter referral code here'}
					className={'mb-4'}
					onChange={(e) => {
						handleChange(e);
						dispatch(authActions.regAdvocateUsername(e?.target?.value));
					}}
				/>

				<Button
					text={'Continue'}
					data-testid="register-button"
					disabled={
						!data?.input || !data?.countryCode || !validInput ? true : false
					}
					className={
						'text-[15px] text-black font-bold btn-primary h-[56px] rounded-full mb-3 justify-center block w-full px-8'
					}
					onClick={onSubmit}
				/>

				<p className="my-3 text-xs font-medium text-grey">
					By joining Hammer Games, you agree that you are 18+ years old and you
					accept our{' '}
					<span
						className="text-green"
						onClick={openTerms}
						data-testid="to-terms-of-service"
					>
						Terms of Service,
					</span>{' '}
					<span
						className="text-green"
						onClick={openPrivacy}
						data-testid="to-privacy-policy"
					>
						Privacy Policy
					</span>{' '}
					and to receive emails and updates.
				</p>
			</AuthFormAction>
		</div>
	);
};

const EmailRegistration = ({
	selectCountryOption,
	handleChange, // setInputType,
}: any) => {
	// const dispatch = useDispatch();
	return (
		<form className="mb-4">
			<SearchableCountries
				options={CountryList}
				label="Select your country"
				name={'restaurant_id'}
				placeholder={'Select your country'}
				selectOption={selectCountryOption}
				data-testid="register-email-countrylist"
			/>

			<TextInput
				data-testid="emailInput"
				type={'email'}
				name={'input'}
				label={'Enter Email'}
				onChange={(e) => {
					handleChange(e);
				}}
				className="mt-4"
				placeholder={'Enter email here'}
				// subLabel={
				// 	<button
				// 		type="button"
				// 		onClick={() => {
				// 			setInputType('phone');
				// 			dispatch(authActions.regPhone(''));
				// 		}}
				// 		className="text-text-green text-sm btn-primary-10 rounded-full flex items-center justify-center gap-2 px-4 py-1 mb-1"
				// 	>
				// 		<img src={Phone} alt="Phone" />
				// 		Phone
				// 	</button>
				// }
			/>
		</form>
	);
};

const PhoneRegistration = ({
	selectCountryOption,
	handlePhone,
	data,
	setInputType,
}: any) => {
	const dispatch = useDispatch();
	return (
		<form className="mb-8">
			<SearchableCountries
				options={PhoneCountryList}
				label="Select your country"
				name={'restaurant_id'}
				placeholder={'Select your country'}
				selectOption={selectCountryOption}
				data-testid="register-phone-countrylist"
			/>

			<PhoneInput
				type={'tel'}
				name={'input'}
				label={'Enter Phone No.'}
				country={data?.countryCode}
				onChange={(e) => {
					handlePhone(e);
				}}
				className="mt-4"
				subLabel={
					<button
						type="button"
						onClick={() => {
							setInputType('email');
							dispatch(authActions.regEmail(''));
						}}
						data-testid="register-email-switch"
						className="text-text-green text-sm btn-primary-10 rounded-full flex items-center justify-center gap-2 px-4 py-1 mb-1"
					>
						<img src={Mail} alt="Phone" />
						Email
					</button>
				}
				data-testid="register-phone"
			/>
		</form>
	);
};
