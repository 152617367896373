import { FC, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { RadioGroup } from '@headlessui/react';
import { AuthFormAction } from 'components/forms/auth/layout';
import { Button } from 'components/button/Button';
import { ReactComponent as Back } from 'assets/icons/arrow-left-circle.svg';
import { getAllSubscriptions, subscribeMember } from 'api/profileApiService';
// import { authActions } from 'store/slices/authSlice';

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

interface Plan {
	amount: number;
	createdAt: string;
	description: string;
	merchantId: string;
	status: string;
	subscriptionPeriod: string;
	title: string;
	updatedAt: string;
	__v: 0;
	_id: string;
}

export const MTNSubscriptionPage: FC = () => {
	// const dispatch = useDispatch();
	const navigate = useNavigate();

	const [selectedPlan, setSelectedPlan] = useState<Plan>();

	console.log('selectedPlan', selectedPlan);

	const { data: allSubscription } = useQuery(
		['all-subscription'],
		async () => await getAllSubscriptions(),
		{
			retry: 0,
		}
	);

	console.log(
		'allSubscription',
		allSubscription,
		allSubscription?.data?.itemList
	);

	const { isLoading, mutate } = useMutation(subscribeMember, {
		onError: (error: Error) => {
			console.log('error', error);
			// errorHandler(error, true);
		},
		onSuccess: (res: any) => {
			console.log('res', res);

			// successHandler(res, true);
			// navigate to the success page
			navigate('/auth/subscription-sucess');
		},
		retry: 0,
	});

	console.log('isLoading', isLoading);

	const onSubmit = () => {
		mutate({
			subscriptionPlanId: selectedPlan?._id,
			fundingType: 'wallet',
		});
	};

	return (
		<div className="lg:grid grid-cols-1 items-center gap-5 text-white px-0 sm:px-4">
			<div className="flex gap-2 items-center mb-8">
				<Back
					className="w-[34px] h-[34px] cursor-pointer"
					onClick={() => navigate(-1)}
					data-testid="back-to-homepage"
				/>
				<div>
					<h3 className="text-2xl font-bold">Subscription Plan</h3>{' '}
					<p className="text-[#848AA0] text-sm font-medium">
						Choose your MTN subscription plan
					</p>
				</div>
			</div>

			<AuthFormAction>
				<h3 className="font-medium text-grey text-[15px] mb-6">
					Make sure you have enough airtime on your balance before paying for a
					plan
				</h3>

				<RadioGroup value={selectedPlan} onChange={setSelectedPlan}>
					<div className="mt-4 mb-8 grid grid-cols-1 gap-y-6 md:grid-cols-2 sm:gap-x-4">
						{allSubscription &&
							allSubscription?.data?.itemsList &&
							allSubscription?.data?.itemsList.length > 0 &&
							allSubscription?.data?.itemsList.map(
								(subscription: any, index: number) => (
									<RadioGroup.Option
										key={index}
										value={subscription}
										className={({ checked, active }) =>
											classNames(
												checked ? 'border-transparent' : 'border-[#424550]',
												active ? 'border-[#84C70D] ring-2 ring-[#84C70D]' : '',
												'relative border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
											)
										}
									>
										{({ checked, active }) => (
											<>
												<span className="flex-1 flex">
													<span className="flex flex-col">
														<RadioGroup.Label
															as="span"
															className="block font-medium text-[#848AA0] capitalize"
														>
															{subscription.title}
														</RadioGroup.Label>
														<RadioGroup.Label
															as="span"
															className="block text-xs font-medium text-[#848AA0] capitalize"
														>
															({subscription.subscriptionPeriod})
														</RadioGroup.Label>
														<RadioGroup.Description
															as="span"
															className="mt-1 flex items-center text-lg text-[#84C70D]"
														>
															{subscription.amount}
														</RadioGroup.Description>
														<RadioGroup.Description
															as="span"
															className="mt-1 text-sm font-medium text-[#585C6B]"
														>
															~ {subscription.amount}
														</RadioGroup.Description>
													</span>
												</span>
												{/* The circular radio button design */}
												<span
													className={classNames(
														checked
															? 'bg-[#84C70D] border-transparent'
															: 'bg-transparent border-[#585C6B]',
														active ? 'ring-2 ring-offset-2 ring-[#84C70D]' : '',
														'h-4 w-4 rounded-full border flex items-center justify-center'
													)}
													aria-hidden="true"
												>
													<span className="rounded-full w-1.5 h-1.5" />
												</span>
												{/* The circular radio button design ends */}
												<span
													className={classNames(
														active ? 'border' : 'border-2',
														checked ? 'border-[#84C70D]' : 'border-transparent',
														'absolute -inset-px rounded-lg pointer-events-none'
													)}
													aria-hidden="true"
												/>
											</>
										)}
									</RadioGroup.Option>
								)
							)}
					</div>
				</RadioGroup>

				<Button
					text={'Continue'}
					data-testid="subscribe-button"
					disabled={!selectedPlan || isLoading}
					loading={isLoading}
					className={
						'text-[15px] text-black font-bold btn-primary h-[56px] rounded-full mb-3 justify-center block w-full px-8'
					}
					onClick={onSubmit}
				/>
			</AuthFormAction>
		</div>
	);
};
