import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { SocialLogin } from './socialLogin';
import { AuthFormTitle } from './layout/index';
import { AuthFormAction } from './layout/index';
import TextInput from '../../inputs/text';
import PhoneCountryInput from 'components/inputs/phoneCountry';
import { Button } from 'components/button/Button';
import Mail from 'assets/icons/mail-outline.svg';
// import Phone from 'assets/icons/phone-outline.svg';
import { ReactComponent as Back } from '../../../assets/icons/arrow-left-circle.svg';
import { loginUserApi } from 'api/apiRequest';
import successHandler from 'handlers/successHandler';
import errorHandler from 'handlers/errorHandler';
import { deleteReferralUsername } from 'utils';
import { authActions } from 'store/slices/authSlice';
import { toast } from 'react-toastify';

type Props = {
	setFormView: React.Dispatch<React.SetStateAction<any>>;
	setOpen: React.Dispatch<React.SetStateAction<any>>;
	sender?: string;
	onLoginSuccess: () => void;
};

export const LoginForm: FC<Props> = ({
	setFormView,
	setOpen,
	onLoginSuccess,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { state } = useLocation();

	const [inputType, setInputType] = useState('email');
	const phone_pattern = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
	const [countryCode, setCountryCode] = useState('NG');

	const [data, setData] = useState({
		username: '',
		password: '',
		code: 'string',
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		if (name === 'username') {
			const new_value = value.toLowerCase();
			setData({ ...data, [name]: new_value });
		} else {
			setData({ ...data, [name]: value });
		}
	};
	const handlePhone = (e: any) => {
		const { name, value } = e.target;

		if (name === 'username') {
			setData({ ...data, username: value });
		}
		if (name === 'phoneCode') {
			setCountryCode(value);
		}
	};

	useEffect(() => {
		const listener = (event: any) => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				event.preventDefault();
				onSubmit();
			}
		};
		document.addEventListener('keydown', listener);
		return () => {
			document.removeEventListener('keydown', listener);
		};
	}, [data]);

	const { isLoading, mutate } = useMutation(loginUserApi, {
		onError: (error: any) => {
			if (error && error?.action === 'not_verified_email') {
				if (phone_pattern.test(data?.username)) {
					setFormView('resend-phone-otp');
					dispatch(authActions.regPhone(data?.username));
					dispatch(authActions.regCountry(countryCode));
				} else {
					setFormView('resend-otp');
					dispatch(authActions.regEmail(data?.username));
				}
			} else if (error && error?.message_language_code === 'auth_0153') {
				setFormView('login-otp');
				dispatch(authActions.regEmail(data?.username));
				dispatch(authActions.regPassword(data?.password));
			} else if (error && error?.action === 'not_verified_phone') {
				toast.error(error?.message, {
					position: toast.POSITION.TOP_RIGHT,
					theme: 'colored',
					autoClose: 6000,
				});
				setFormView('resend-phone-otp');
				dispatch(authActions.regPhone(data?.username));
				dispatch(authActions.regPassword(data?.password));
				dispatch(authActions.regCountry(countryCode));
			} else {
				errorHandler(error, true);
			}
		},
		onSuccess: (res: any) => {
			localStorage.setItem('game-user-token', res?.data?.accessToken);
			localStorage.setItem('game-user-secret', res?.data?.accessSecret);
			if (res?.data?.user) {
				localStorage.setItem('game-user-obj', JSON.stringify(res?.data?.user));
			}
			deleteReferralUsername();
			successHandler(res, true);
			if (state === 'email-subscriptions') {
				navigate('/email-subscriptions');
			} else {
				onLoginSuccess();
				navigate('/');
			}

			setOpen(false);
		},
		retry: 0,
	});
	// sender

	const onSubmit = () => {
		// const formData = {};
		if (phone_pattern.test(data.username)) {
			const phoneData = {
				...data,
				userPhoneCountryCode: countryCode,
			};

			mutate(phoneData);
		} else {
			mutate(data);
		}
	};

	return (
		<div className="lg:grid grid-cols-1 sm:grid-cols-2 items-center gap-5 text-white px-0 sm:px-4">
			<AuthFormTitle>Welcome Back, Login to continue playing</AuthFormTitle>

			<AuthFormAction>
				<div
					className="flex gap-2 items-center mb-8 cursor-pointer"
					onClick={() => setFormView('join')}
					data-testid="to-join-button"
				>
					<Back className="w-[34px] h-[34px]" />
					<h3 className="text-2xl font-bold max-w-[200px]">Login and Play</h3>
				</div>

				<div>
					{inputType === 'email' && (
						<TextInput
							type={'username'}
							name={'username'}
							label={'Enter Email'}
							onChange={(e) => {
								handleChange(e);
							}}
							className="mt-4"
							data-testid="login-username"
							placeholder={'Enter email here'}
							// subLabel={
							// 	<button
							// 		type="button"
							// 		onClick={() => {
							// 			setInputType('phone');
							// 			setData({
							// 				...data,
							// 				username: '',
							// 			});
							// 		}}
							//	data-testid="login-phone-switch"
							// 		className="text-text-green text-sm btn-primary-10 rounded-full flex items-center justify-center gap-2 px-4 py-1 mb-1"
							// 	>
							// 		<img src={Phone} alt="Phone" />
							// 		Phone
							// 	</button>
							// }
						/>
					)}
					{inputType === 'phone' && (
						<PhoneCountryInput
							type={'tel'}
							name={'username'}
							label={'Enter Phone No.'}
							value={countryCode}
							country={countryCode}
							onChange={(e) => {
								handlePhone(e);
							}}
							className="mt-4"
							data-testid="login-phone"
							placeholder={'Enter phone number here'}
							subLabel={
								<button
									type="button"
									onClick={() => {
										setInputType('email');
										setData({
											...data,
											username: '',
										});
									}}
									data-testid="login-email-switch"
									className="text-text-green text-sm btn-primary-10 rounded-full flex items-center justify-center gap-2 px-4 py-1 mb-1"
								>
									<img src={Mail} alt="Phone" />
									Email
								</button>
							}
						/>
					)}

					<TextInput
						passwordInput={true}
						name={'password'}
						label={'Enter password'}
						data-testid="login-password"
						placeholder={'Enter password here'}
						onChange={(e) => handleChange(e)}
					/>
					<div className="flex justify-end mb-8 mt-6 sm:mb-4">
						<span
							data-testid="login-forgot-password"
							className="text-[14px] text-green font-bold cursor-pointer"
							onClick={() => {
								setOpen(false);
								navigate('/auth/forgot-password');
							}}
						>
							Forgot password?
						</span>
					</div>
					<Button
						text={'Login'}
						type="submit"
						loading={isLoading}
						disabled={isLoading}
						onClick={() => onSubmit()}
						data-testid="login-button"
						className={
							'text-[15px] text-black font-bold btn-primary [#2C4204] hover:bg-[#2C4204] h-[56px] rounded-full mb-3 justify-center block w-full px-8'
						}
					/>
				</div>

				<SocialLogin setOpen={setOpen} onLoginSuccess={onLoginSuccess} />

				<div className="text-sm mt-5 text-center font-bold">
					Not a member yet?{' '}
					<span
						data-testid="signup-button"
						className="cursor-pointer text-green"
						onClick={() => setFormView('register')}
					>
						Sign up on Hammer Games
					</span>
				</div>
			</AuthFormAction>
		</div>
	);
};
