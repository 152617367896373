import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { _isUserLoggedIn } from 'utils';
import Image1 from 'assets/onboarding/hammerLoadingAd.png';
import { setLoginSuccessFunction } from 'utils/loginSuccess';

interface Props {
	onLoginSuccess: () => void;
}

export function LoadingPage({ onLoginSuccess }: Props) {
	const navigate = useNavigate();
	const loggedIn = _isUserLoggedIn();

	// Store the function in the singleton
	setLoginSuccessFunction(onLoginSuccess);

	useEffect(() => {
		// Preload images and GIFs
		const imagesToPreload = [Image1];
		imagesToPreload.forEach((src: any) => {
			const img = new Image();
			img.src = src;
		});
	}, []);

	useEffect(() => {
		if (!loggedIn) {
			navigate('/auth/get-started');
		} else {
			onLoginSuccess();
			navigate('/');
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div className="relative max-w-[540px] w-full mx-auto h-screen overflow-hidden loading-page-bg">
			<img src={Image1} alt="Image" className="absolute" />
		</div>
	);
}
