import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthFormTitle } from './layout/index';
import { AuthFormAction } from './layout/index';
import TextInput from '../../inputs/text';
import { Button } from 'components/button/Button';
import { ResendVerification } from './ResendVerification';
import { ReactComponent as Back } from '../../../assets/icons/arrow-left-circle.svg';
import { verifyUserApi, resendVerificationApi } from 'api/apiRequest';
import errorHandler from 'handlers/errorHandler';

type Props = {
	setFormView: React.Dispatch<React.SetStateAction<any>>;
};

export const ResendOtpForm: FC<Props> = ({ setFormView }) => {
	const [data, setData] = useState('');
	const [getCode, setGetCode] = useState(true);
	const [defaultTimer, setDefaultTimer] = useState(false);
	const { email } = useSelector((state: any) => state.authReducer);

	const { isLoading, mutate } = useMutation(verifyUserApi, {
		onError: (error: Error) => {
			errorHandler(error, true);
		},
		onSuccess: () => {
			setFormView('login');
		},
		retry: 0,
	});

	const { mutate: getCodeMutate } = useMutation(resendVerificationApi, {
		onError: (error: Error) => {
			errorHandler(error, true);
		},
		onSuccess: (res: any) => {
			toast.success(res?.message, {
				position: toast.POSITION.TOP_RIGHT,
				theme: 'colored',
				autoClose: 6000,
			});
			setDefaultTimer(true);
			setGetCode(false);
		},
		retry: 0,
	});

	const onSubmit = () => {
		const formData = {
			email: email,
			otp: data,
		};
		mutate(formData);
	};

	return (
		<div className="lg:grid grid-cols-1 sm:grid-cols-2 items-center gap-5 text-white px-0 sm:px-4">
			<AuthFormTitle>
				Sign up to join a community of millions of players.
			</AuthFormTitle>

			<AuthFormAction>
				<div
					className="flex gap-2 items-center mb-3 cursor-pointer"
					onClick={() => setFormView('join')}
					data-testid="to-join-button"
				>
					<Back className="w-[34px] h-[34px]" />
					<h3 className="text-2xl font-bold max-w-[200px]">Verify Email</h3>
				</div>
				<p className="font-medium text-grey">
					Please enter the security verification code sent to your email
				</p>
				<p className="mb-10 font-medium text-green">{email}</p>

				<TextInput
					type={'number'}
					label={'Enter verification code'}
					onChange={(e) => setData(e.target.value)}
				/>
				{getCode && (
					<button
						data-testid="verification-code"
						className="mb5 mt2 text-bold text-green text-right"
						type="button"
						onClick={() =>
							getCodeMutate({
								email: email,
							})
						}
					>
						Get Code Now
					</button>
				)}

				<Button
					text={'Verify Email'}
					loading={isLoading}
					data-testid="resend-email-verification-button"
					className={
						'text-[15px] text-black font-bold btn-primary h-[56px] rounded-full mb-3 mt-5 justify-center block w-full px-8'
					}
					onClick={() => onSubmit()}
				/>

				<ResendVerification defaultTimer={defaultTimer} />
			</AuthFormAction>
		</div>
	);
};
