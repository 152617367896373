import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import TextInput from 'components/inputs/text';
import PhoneCountryInput from 'components/inputs/phoneCountry';
import { Button } from 'components/button/Button';
import { AuthFormAction } from 'components/forms/auth/layout';
import Mail from 'assets/icons/mail-outline.svg';
import Phone from 'assets/icons/phone-outline.svg';
import { ReactComponent as Back } from 'assets/icons/arrow-left-circle.svg';
import { forgotPasswordApi } from 'api/apiRequest';
import { authActions } from 'store/slices/authSlice';
import successHandler from 'handlers/successHandler';
import errorHandler from 'handlers/errorHandler';
import { cleanInput } from 'utils/cleanInput';

export const ForgotPassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [data, setData] = useState({
		email: '',
		userPhone: '',
		userPhoneCountryCode: 'NG',
	});
	const [inputType, setInputType] = useState('email');
	const [isValidEmail, setIsValidEmail] = useState(false);

	const { isLoading, mutate } = useMutation(forgotPasswordApi, {
		onError: (error: Error) => {
			errorHandler(error, true);
		},
		onSuccess: (res: any) => {
			if (data?.userPhone === '') {
				navigate('/auth/verify-email');
			} else {
				navigate('/auth/verify-phone');
			}

			successHandler(res, true);
		},
		retry: 0,
	});

	const validateEmail = (email: string) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const new_value = value.toLowerCase();
		setData({ ...data, [name]: new_value });
		dispatch(authActions.regEmail(new_value));
		//check if email is valid or not
		if (validateEmail(value)) {
			setIsValidEmail(true);
		} else {
			setIsValidEmail(false);
		}
	};

	const handlePhone = (e: any) => {
		const { name, value } = e.target;
		setData({ ...data, [name]: value });

		if (name === 'userPhone') {
			setData({ ...data, userPhone: value });
			dispatch(authActions.regPhone(value));
		}
		if (name === 'phoneCode') {
			setData({ ...data, userPhoneCountryCode: value });
		}
	};

	const onSubmit = () => {
		const formData = {
			...data,
		};
		dispatch(authActions.regCountry(data?.userPhoneCountryCode));
		cleanInput(formData);

		if (!data?.userPhone) {
			mutate({
				email: data?.email,
			});
		} else {
			mutate(formData);
		}
	};

	return (
		<div className="text-white px-4 mx-auto max-w-[464px]">
			<div className="flex gap-2 items-center mb-3">
				<Back
					data-testid="go-back-forgot-password"
					className="w-[34px] h-[34px] cursor-pointer"
					onClick={() => navigate(-1)}
				/>
				<h3 className="text-2xl font-bold max-w-[200px]">Forgot Password</h3>
			</div>

			<AuthFormAction>
				<p className="mb-6 text-grey">
					Forgot your password? No worries, we’ll send you reset instructions to
					the email/phone linked to your account.
				</p>

				<form className="mb-8">
					{inputType === 'email' && (
						<TextInput
							type={'email'}
							name={'email'}
							label={'Enter Email'}
							onChange={(e) => {
								handleChange(e);
							}}
							className="mt-4"
							disabled={isLoading}
							placeholder={'Enter email here'}
							data-testid="forgot-password-email"
							subLabel={
								<button
									type="button"
									onClick={() => {
										setInputType('phone');
										dispatch(authActions.regEmail(''));
										setData({
											...data,
											email: '',
										});
									}}
									className="text-text-green text-sm btn-primary-10 rounded-full flex items-center justify-center gap-2 px-4 py-1 mb-1"
								>
									<img src={Phone} alt="Phone" />
									Phone
								</button>
							}
						/>
					)}
					{inputType === 'phone' && (
						<PhoneCountryInput
							type={'tel'}
							name={'userPhone'}
							label={'Enter Phone No.'}
							value={data?.userPhoneCountryCode}
							country={data?.userPhoneCountryCode}
							onChange={(e) => {
								handlePhone(e);
							}}
							className="mt-4"
							data-testid="forgot-passord-phone"
							placeholder={'Enter phone number here'}
							subLabel={
								<button
									type="button"
									onClick={() => {
										setInputType('email');

										dispatch(authActions.regPhone(''));
										dispatch(authActions.regCountry(''));
										setData({
											...data,
											userPhone: '',
											userPhoneCountryCode: 'NG',
										});
									}}
									className="text-text-green text-sm btn-primary-10 rounded-full flex items-center justify-center gap-2 px-4 py-1 mb-1"
								>
									<img src={Mail} alt="Phone" />
									Email
								</button>
							}
						/>
					)}
				</form>

				<Button
					text={'Reset Password'}
					disabled={
						(inputType === 'email' && !isValidEmail) ||
						(inputType === 'phone' && data?.userPhone.length < 11) ||
						(inputType === 'phone' && data?.userPhone.length > 11)
					}
					loading={isLoading}
					onClick={() => onSubmit()}
					data-testid="forgot-password-button"
					className={
						'text-[15px] text-black font-bold btn-primary h-[56px] rounded-full mb-3 justify-center block w-full px-8'
					}
				/>
			</AuthFormAction>
		</div>
	);
};
