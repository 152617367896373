import { Sidebar } from 'components';
import { Navbar } from 'components/navigation/Navbar';
import { ReactNode } from 'react';

type ILayoutProps = {
	children: ReactNode;
	theme: string;
	toggleTheme: (e: string) => void;
	showSidebar?: boolean;
};

export const AppLayout = ({
	children,
	theme,
	toggleTheme,
	showSidebar = true,
}: ILayoutProps) => {
	// const dispatch = useDispatch();

	// const { showAnnouncement } = useSelector((state: any) => state.userReducer);

	return (
		<div
			className="bg-primary max-w-[540px] w-full mx-auto h-screen overflow-hidden"
			style={{
				background:
					'linear-gradient(180.47deg, #042426 26.7%, #101936 47.48%, #070A24 72.12%, #412A08 94.57%)',
			}}
		>
			{/* {showAnnouncement && (
				<div className="hidden bg-[#FFE8D8] h-[3.5rem] w-full relative tb:flex items-center justify-center">
					<span className="text-[#1D202B] text-[0.875rem] font-medium">
						Please upgrade your account to be able to use wallet.
					</span>
					<span className="ml-4 text-[#FF8B3E] text-[0.875rem] font-medium underline ">
						Verify KYC
					</span>
					<button
						type="button"
						onClick={() => dispatch(userActions.setShowAnnouncement(false))}
						className="flex items-center justify-center h-[2rem] w-[2rem] absolute right-[3rem] rounded-full bg-bg-dark "
					>
						<img src={modal_close} alt="" className="h-[1.5rem] w-[1.5rem]" />
					</button>
				</div>
			)} */}
			<Navbar
				theme={theme}
				toggleTheme={toggleTheme}
				className="h-[8vh] md:h-[5rem] "
			/>
			<main className="w-full h-[90vh] min-h-0 lg:min-h-[90vh] overflow-y-auto overflow-x-hidden flex ">
				{showSidebar && (
					<Sidebar className="hidden lg:w-[20rem] h-full overflow-hidden" />
				)}
				<main className="w-full h-full overflow-y-auto md:pt-6 pt-3 pb-3 px-0 md:px-6 ">
					{children}
				</main>
			</main>
			{showSidebar && (
				<div className="px-3 fixed bottom-3 w-full max-w-[540px] z-[10]">
					<Sidebar className="flex items-center md:flex w-full h-[10vh] px-2 bg-[#FFFFFF14] border border-solid border-[#FFFFFF14] rounded-[20px] backdrop-blur-[15px]" />
				</div>
			)}
		</div>
	);
};
