import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/button/Button';
import { AuthFormAction } from 'components/forms/auth/layout';
import { circleCheckValidation } from 'assets';
import { AuthModal } from 'components/modals/auth';

interface Props {
	onLoginSuccess: () => void;
}

export const ResetSuccess = ({ onLoginSuccess }: Props) => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);

	return (
		<div className="text-white text-center px-4 mx-auto max-w-[464px] w-full">
			<AuthFormAction>
				<h3 className="text-[28px] font-bold mb-5">Done!</h3>
				<img
					src={circleCheckValidation}
					alt="validation"
					className="h-[120px] w-[120px] mx-auto mb-6"
				/>
				<p className="mb-12 text-[22px] text-grey font-bold mx-auto max-w-[296px]">
					<span className="text-green">Hey! Chief,</span> password reset
					completed. Please login
				</p>

				<div className="flex gap-3">
					<Button
						text={'Login Now'}
						data-testid="to-login-button"
						onClick={() => navigate('/auth/get-started')}
						className={
							'text-[15px] text-black font-bold btn-primary h-[56px] rounded-full mb-3 justify-center block w-full px-8'
						}
					/>
				</div>
			</AuthFormAction>

			<AuthModal
				open={open}
				setOpen={setOpen}
				onLoginSuccess={onLoginSuccess}
			/>
		</div>
	);
};
