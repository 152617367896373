import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import TextInput from 'components/inputs/text';
import { Button } from 'components/button/Button';
import { ReactComponent as Back } from 'assets/icons/arrow-left-circle.svg';
import { AuthFormAction } from 'components/forms/auth/layout';
import { authActions } from 'store/slices/authSlice';

export const VerifyPhone = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [otp, setOtp] = useState('');
	const { userPhone } = useSelector((state: any) => state.authReducer);

	return (
		<div className="text-white px-4 mx-auto max-w-[464px]">
			<div className="flex gap-2 items-center mb-3">
				<Back
					className="w-[34px] h-[34px] cursor-pointer"
					onClick={() => navigate(-1)}
					data-testid="to-forgot-password"
				/>
				<h3 className="text-2xl font-bold max-w-[200px]">Verification</h3>
			</div>

			<AuthFormAction>
				<p className="font-medium text-grey">
					Please enter the security verification code sent to your phone
				</p>
				<p className="mb-10 font-medium text-green">{userPhone}</p>

				<TextInput
					type={'text'}
					label={'Enter verification code'}
					onChange={(e) => {
						setOtp(e.target.value);
						dispatch(authActions.regOtp(e?.target?.value));
					}}
				/>

				<Button
					text={'Continue'}
					disabled={!otp}
					data-testid="to-reset-password"
					className={
						'text-[15px] text-black font-bold btn-primary h-[56px] rounded-full mb-3 mt-5 justify-center block w-full px-8'
					}
					onClick={() => navigate('/auth/reset-password')}
				/>
			</AuthFormAction>
		</div>
	);
};
