/* eslint-disable @typescript-eslint/no-empty-function */
import { Fragment, ReactNode, FC } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import HalfLogo from '../../../assets/images/half-logo.png';
import { ReactComponent as Close } from '../../../assets/icons/close.svg';
import { logo } from 'assets';

type Props = {
	children: ReactNode;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setFormView: (e: string) => void;
};

export const AuthModalLayout: FC<Props> = ({
	children,
	open,
	setOpen,
	setFormView,
}) => {
	const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={() => {}}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-start sm:items-center justify-center min-h-full text-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel
								as="div"
								onClick={(e: any) => e.stopPropagation()}
								className="relative bg-primary rounded-lg px-4 sm:px-10 pt-5 sm:pb-12 sm:pt-16 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 md:my-0 sm:max-w-[1108px] w-full"
							>
								<button
									type="button"
									onClick={() => {
										setOpen(false);
										setFormView('join');
									}}
									className="absolute flex items-center justify-center top-6 right-6 sm:right-12 h-[36px] sm:h-[56px] w-[36px] sm:w-[56px] rounded-full bg-[#1D202B] outline-0"
								>
									<Close className="h-[21px] sm:h-[34px] w-[21px] sm:w-[34px]" />
								</button>
								<div className="sm:absolute">
									{isMobile() ? (
										<img
											src={HalfLogo}
											alt="Logo"
											className="max-w-[126.5px]"
										/>
									) : (
										<img src={logo} alt="Logo" />
									)}
								</div>

								<div className="mt-5 sm:mt-16">
									<div>{children}</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};
