import { useEffect, useState } from 'react';
import { AuthModalLayout } from './authLayout';
import { JoinForm } from 'components/forms/auth/Join';
import { JoinWithEmailForm } from 'components/forms/auth/JoinWithEmail';
import { LoginForm } from 'components/forms/auth';
import { VerifyEmailForm } from 'components/forms/auth/VerifyEmail';
import { VerifyPhoneForm } from 'components/forms/auth/VerifyPhone';
import { CreatePasswordForm } from 'components/forms/auth/CreatePassword';
import { Onboarding } from 'components/forms/auth/Onboarding';
import { ResendOtpForm } from 'components/forms/auth/ResendOtp';
import { ResendPhoneOtpForm } from 'components/forms/auth/ResendPhoneOtp';
import { LoginOtpForm } from 'components/forms/auth/LoginOtp';

export const AuthModal = ({
	open,
	setOpen,
	viewToDisplay,
	sender,
	onLoginSuccess,
}: {
	open: boolean;
	setOpen: any;
	viewToDisplay?: string;
	sender?: string;
	onLoginSuccess: () => void;
}) => {
	const [formView, setFormView] = useState('join');

	useEffect(() => {
		if (
			viewToDisplay &&
			[
				'join',
				'register',
				'login',
				'verify-email',
				'create-password',
				'onboarding',
			].includes(viewToDisplay)
		) {
			setFormView(viewToDisplay);
		}
	}, [viewToDisplay]);

	return (
		<AuthModalLayout open={open} setOpen={setOpen} setFormView={setFormView}>
			<div className="min-h-screen  md:min-h-screen lg:min-h-full">
				{formView === 'join' && (
					<JoinForm
						setFormView={setFormView}
						setOpen={setOpen}
						onLoginSuccess={onLoginSuccess}
					/>
				)}
				{formView === 'register' && (
					<JoinWithEmailForm setFormView={setFormView} />
				)}
				{formView === 'login' && (
					<LoginForm
						setFormView={setFormView}
						setOpen={setOpen}
						sender={sender}
						onLoginSuccess={onLoginSuccess}
					/>
				)}
				{formView === 'login-otp' && <LoginOtpForm setFormView={setFormView} />}
				{formView === 'resend-otp' && (
					<ResendOtpForm setFormView={setFormView} />
				)}
				{formView === 'resend-phone-otp' && (
					<ResendPhoneOtpForm setFormView={setFormView} />
				)}
				{formView === 'verify-email' && (
					<VerifyEmailForm setFormView={setFormView} setOpen={setOpen} />
				)}
				{formView === 'verify-phone' && (
					<VerifyPhoneForm setFormView={setFormView} />
				)}
				{formView === 'create-password' && (
					<CreatePasswordForm setFormView={setFormView} />
				)}
				{formView === 'onboarding' && <Onboarding setOpen={setOpen} />}
			</div>
		</AuthModalLayout>
	);
};
