import { ReactNode } from 'react';

type ILayoutProps = {
	children: ReactNode;
	theme: string;
	toggleTheme: (e: string) => void;
};

export const PromoLayout = ({ children }: ILayoutProps) => {
	return (
		<div className="bg-primary max-w-[540px] w-full mx-auto min-h-screen overflow-hidden border border-red-500">
			<main className="w-full h-full min-h-0 md:min-h-[100vh] overflow-hidden">
				<main className="w-full h-full overflow-y-auto flex lg:items-center justify-center">
					{children}
				</main>
			</main>
		</div>
	);
};
