import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { logo } from 'assets';

type ILayoutProps = {
	children: ReactNode;
	theme: string;
	toggleTheme: (e: string) => void;
};

export const AuthLayout = ({ children }: ILayoutProps) => {
	const navigate = useNavigate();
	return (
		<div className="bg-primary max-w-[540px] w-full mx-auto h-screen overflow-hidden">
			<div className="h-[10vh] md:h-[6rem] w-full px-[3%] box-border flex items-center justify-between  py-[0.5rem] overflow-x-auto">
				<img
					src={logo}
					alt="logo"
					onClick={() => navigate('/')}
					className="cursor-pointer w-[5.5rem] md:w-auto py-2"
				/>
			</div>

			<main className="w-full h-[77vh] min-h-0 md:min-h-[90vh] overflow-hidden">
				<main className="w-full h-full overflow-y-auto md:pt-6 pt-3 pb-3 px-0 md:px-6 flex items-center justify-center">
					{children}
				</main>
			</main>
		</div>
	);
};
