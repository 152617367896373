import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import TextInput from 'components/inputs/text';
import { Button } from 'components/button/Button';
import { AuthFormAction } from 'components/forms/auth/layout';
import { PasswordStrength } from 'components/common/PasswordStrength';
import { authActions } from 'store/slices/authSlice';
import { ReactComponent as Back } from 'assets/icons/arrow-left-circle.svg';
import { resetPasswordApi } from 'api/apiRequest';
import successHandler from 'handlers/successHandler';
import errorHandler from 'handlers/errorHandler';

export const ResetPassword = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [disabled, setDisabled] = useState(true);
	const { email, otp, userPhone, countryCode } = useSelector(
		(state: any) => state.authReducer
	);

	const handleChange = (e: any) => {
		const { value } = e.target;
		setConfirmPassword(value);
		if (value === password && password.length > 5) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	};

	const { isLoading, mutate } = useMutation(resetPasswordApi, {
		onError: (error: Error) => {
			errorHandler(error, true);
			// dispatch(authActions.regEmail(''));
			// dispatch(authActions.regPhone(''));
			// dispatch(authActions.regCountry(''));
		},
		onSuccess: (res: any) => {
			navigate('/auth/reset-success');
			successHandler(res, true);
			dispatch(authActions.regEmail(''));
			dispatch(authActions.regPhone(''));
			dispatch(authActions.regCountry(''));
			dispatch(authActions.regOtp(''));
		},
		retry: 0,
	});

	const onSubmit = () => {
		if (userPhone === '') {
			const formData = {
				email: email,
				password: password,
				otp: otp,
			};
			mutate(formData);
		} else {
			const formData = {
				userPhone: userPhone,
				userPhoneCountryCode: countryCode,
				password: password,
				otp: otp,
			};
			mutate(formData);
		}
	};

	// console.log('-isError- ', isError);

	return (
		<div className="text-white px-4 mx-auto max-w-[464px]">
			<div className="flex gap-2 items-center mb-3">
				<Back
					className="w-[34px] h-[34px] cursor-pointer"
					onClick={() => {
						navigate(-1);
					}}
					data-testid="back-to-homepage"
				/>
				<h3 className="text-2xl font-bold max-w-[200px]">Reset Password</h3>
			</div>

			<AuthFormAction>
				<h3 className="font-medium text-grey mb-6">
					Please create a strong password to protect your account
				</h3>

				<TextInput
					label={'New password'}
					passwordInput={true}
					data-testid="new-password"
					subLabel={<PasswordStrength password={password} />}
					onChange={(e) => {
						setPassword(e.target.value);
						dispatch(authActions.regPassword(e?.target?.value));
						if (
							e.target.value === confirmPassword &&
							e.target.value.length > 5
						) {
							setDisabled(false);
						} else {
							setDisabled(true);
						}
					}}
				/>

				<p className="mb-8 text-sm text-red">Minimum of 6 characters.</p>

				<TextInput
					passwordInput={true}
					label={'Confirm password'}
					data-testid="confirm-password"
					onChange={(e) => handleChange(e)}
				/>

				<Button
					text={'Reset Password'}
					loading={isLoading}
					disabled={disabled}
					onClick={() => onSubmit()}
					data-testid="reset-password-button"
					className={
						'text-[15px] text-black font-bold btn-primary h-[56px] rounded-full mt-8 mb-4 justify-center block w-full px-8'
					}
				/>
			</AuthFormAction>
		</div>
	);
};
