import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resendVerificationApi } from 'api/apiRequest';
import errorHandler from 'handlers/errorHandler';

export const ResendVerification = ({
	defaultTimer,
}: {
	defaultTimer: boolean;
}) => {
	const { email } = useSelector((state: any) => state.authReducer);
	const [startTimer, setStartTimer] = useState(false);
	const [timeLeft, setTimeLeft] = useState(120);

	const { mutate, isLoading } = useMutation(resendVerificationApi, {
		onError: (error: Error) => {
			errorHandler(error, true);
		},
		onSuccess: (res: any) => {
			toast.success(res?.message, {
				position: toast.POSITION.TOP_RIGHT,
				theme: 'colored',
				autoClose: 6000,
			});
			startCountdown();
		},
		retry: 0,
	});

	const onSubmit = () => {
		const formData = {
			email: email,
		};
		mutate(formData);
	};

	function startCountdown() {
		let timeLeft = 120;
		setStartTimer(false);

		const countdownInterval = setInterval(function () {
			if (timeLeft <= 0) {
				clearInterval(countdownInterval);
				setStartTimer(true);
			} else {
				timeLeft--;
				setTimeLeft(timeLeft);
			}
		}, 1000); // Update the timer every 1000ms (1 second)
	}

	useEffect(() => {
		if (defaultTimer) {
			startCountdown();
		}
	}, [defaultTimer]);

	return (
		<>
			{defaultTimer && (
				<p className="text-center text-green font-bold mt-6">
					{isLoading ? (
						'Resending...'
					) : startTimer ? (
						<span
							className="cursor-pointer"
							onClick={() => onSubmit()}
							data-testid="resend-email-verification-button"
						>
							Resend
						</span>
					) : (
						<span className="text-grey">
							Resend code in{' '}
							<span className="text-text-green">{timeLeft}s</span>
						</span>
					)}
				</p>
			)}
		</>
	);
};
